import React from 'react';
import "./assets/css/animate.css";
import "./assets/css/main.css";
import "./assets/css/bootstrap.min.css";
// import "./assets/css/font-awesome.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/meanmenu.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/nice-select.css";
// import "./assets/scss/animation.scss";

const Css = () => {
  return (
    <>
      
    </>
  )
}

export default Css;
