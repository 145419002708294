import { FaArrowRightLong } from "react-icons/fa6";

const ProjectData = [
    {
        imgsrc:"../img/project/15.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
    {
        imgsrc:"../img/project/16.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
    {
        imgsrc:"../img/project/06.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
    {
        imgsrc:"../img/project/14.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
    {
        imgsrc:"../img/project/15.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
    {
        imgsrc:"../img/project/16.jpg",
        arrow:<FaArrowRightLong/>,
        para:"San Fransisco",
        designation:"Digital Consulting",
    },
   
]
export default ProjectData