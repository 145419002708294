const HireData = [
    {
        hireus:"Hire us now",
        designation:"UI / EX Designer",
        hour:"Hour",
        daily:"Daily",
        week:"Week",
        price:"$10",
        hireme:" Hire ME",
    },
    {
        hireus:"Hire us now",
        designation:"SEO Digital",
        hour:"Hour",
        daily:"Daily",
        week:"Week",
        price:"$100",
        hireme:" Hire ME",
    },
    {
        hireus:"Hire us now",
        designation:"Graphic Designer",
        hour:"Hour",
        daily:"Daily",
        week:"Week",
        price:"$111",
        hireme:" Hire ME",
    },
]
export default HireData;