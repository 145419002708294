import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faAppleAlt,
  faCarrot,
  faFish,
  faIceCream,
} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './brand-section-3.css';

// import "./BrandSection.css"; // Import the CSS file

const BrandSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="brand-section-3 fix section-padding">
      <div className="layer-shape">
        <img src="../img/layer-shape.png" alt="shape-img" className="dd"/>
      </div>
      <div className="container">
        <div className="brand-wrapper style-3">
          <Slider {...settings}>
            <div className="icon-item">
             
              <img src="../img/brand/01.png" alt="brand1"/>
            </div>
            <div className="icon-item">
            <img src="../img/brand/02.png" alt="brand2"/>

            </div>
            <div className="icon-item">
            <img src="../img/brand/03.png" alt="brand13"/>

            </div>
            <div className="icon-item">
            <img src="../img/brand/04.png" alt="brand4"/>

            </div>
            <div className="icon-item">
            <img src="../img/brand/05.png" alt="brand5"/>

            </div>
          </Slider>
          {/* <div className="button-container"> */}
            {/* <button className="cta-button">Learn More</button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default BrandSection;