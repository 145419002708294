import React from 'react'
import { FaSearch } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";



const FaqSectionStart = () => {
  return (
    <>
        <section className="faq-section fix section-padding">
  <div className="container">
    <div className="faq-wrapper-2">
      <div className="faq-items">
        <div className="section-title mb-0">
          <span
            className="wow fadeInUp"
            style={{ visibility: "visible", animationName: "fadeInUp" }}
          >
            Our question and answer
          </span>
          <h2
            className="wow fadeInUp"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp"
            }}
          >
            Frequently asked question <br /> &amp; answer here
          </h2>
        </div>
        <div className="faq-image">
          <img src="../img/faq.png" alt="faq-img" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="faq-sidebar">
            <div
              className="search-widget wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp"
              }}
            >
              <form action="#">
                <input type="text" placeholder="Search...." />
                <button type="submit">
                <FaSearch/>
                </button>
              </form>
            </div>
            <div
              className="side-post bg-cover wow fadeInUp"
              data-wow-delay=".5s"
              style={{
                backgroundImage: 'url("../img/faq/faq-4.jpg")',
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp"
              }}
            >
              <h3>
                Azent a smart <br /> agency
              </h3>
            </div>
          </div>
        </div>
        <div className="col-lg-1" />
        <div className="col-lg-6">
          {/* <div className="faq-content style-2"> */}
            <Accordion defaultActiveKey="0" flush className='m-0'>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      How can we improve the lead generation process?
                    </Accordion.Header>
                    <Accordion.Body>
                    The main elements of a marketing strategy are your target
                      audiance goals and objectives and the tax you will employ
                      to acitvely markter to your achive the goals
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      What is content marketing stratagy?
                    </Accordion.Header>
                    <Accordion.Body>
                    The main elements of a marketing strategy are your target
                      audiance goals and objectives and the tax you will employ
                      to acitvely markter to your achive the goals
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {" "}
                      What is the purpose of digital agency?
                    </Accordion.Header>
                    <Accordion.Body>
                    The main elements of a marketing strategy are your target
                      audiance goals and objectives and the tax you will employ
                      to acitvely markter to your achive the goals
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      Can I success on this platform with my experience?
                    </Accordion.Header>
                    <Accordion.Body>
                    The main elements of a marketing strategy are your target
                      audiance goals and objectives and the tax you will employ
                      to acitvely markter to your achive the goals
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
          </div>
        </div>
      </div>
    </div>
  {/* </div> */}
</section>

    </>
  )
}

export default FaqSectionStart