import React from 'react'
import BreadCrumb from '../components/cpage/BreadCrumb'
import NewsSection from '../components/cpage/NewsSection'

const Pages = () => {
  return (
    <>
      <BreadCrumb/>
      <NewsSection/>
      
    </>
  )
}

export default Pages